<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Cuentas de banco de provedores</h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        class="special-margin"
        @click="pushBankAccount()"
        >Agregar cuenta de banco extra</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div v-for="(item, index) in accounts" :key="index">
      <h3 class="form-division">Cuenta {{ index + 1 }}</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Compañía de la tarjeta de credito/debito (Requerido)"
          expanded
          label-position="inside"
        >
          <b-select
            placeholder="Ejemplo: Cheque"
            expanded
            v-model="item.BANCO"
            icon="credit-card-multiple-outline"
            class="required-input"
            required
          >
            <option value="Banamex">Banamex</option>
            <option value="Bancomer">Bancomer</option>
          </b-select>
        </b-field>
        <b-field label="Cantidad (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Referencia de cuenta"
            icon="pencil-outline"
            v-model="item.REFERENCIA"
            required
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Plaza (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 43210"
            icon="office-building"
            v-model="item.PLAZA"
            required
          ></b-input>
        </b-field>
        <b-field label="Sucursal (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 0342"
            icon="bank-outline"
            v-model="item.SUCURSAL"
            required
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field
          label="Numero de cuenta (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 00000000000000000000"
            icon="bank"
            v-model="item.NUM_CTA"
            required
          ></b-input>
        </b-field>
        <b-field
          label="Cuenta CLABE (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 000000000000000000"
            icon="badge-account-horizontal-outline"
            v-model="item.CLABE"
            required
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="sendProviderAccounts()"
        >Guardar cuentas de banco de proveedor</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from "../../../event-bus";
export default {
  name: "AddProviderBankAccount",
  props: ["providersAccount", "providerId"],
  components: {},
  data() {
    return {
      currentAccounts: 0,
      accounts: [],
    };
  },
  mounted() {
    if (this.providersAccount.length == 0) {
      let initialAccount = {
        BANCO: "Banamex",
        REFERENCIA: "",
        PLAZA: "",
        SUCURSAL: "",
        NUM_CTA: "",
        CLABE: "",
      };
      this.accounts.push(initialAccount);
      this.currentAccounts++;
    } else {
      this.account = this.providersAccount;
      this.currentAccounts = this.providersAccount.length;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    pushBankAccount() {
      let tempAccount = {
        BANCO: "Banamex",
        REFERENCIA: "",
        PLAZA: "",
        SUCURSAL: "",
        NUM_CTA: "",
        CLABE: "",
      };
      this.accounts.push(tempAccount);
      this.currentAccounts++;
    },
    sendProviderAccounts() {
      if (this.providerId) {
        EventBus.$emit(
          "sendAddProviderBankAccount",
          this.accounts,
          this.providerId
        );
      } else {
        EventBus.$emit("sendAddProviderBankAccount", this.accounts);
      }

      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Se guardó exitosamente las cuentas de proveedores",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {
          this.closeModal();
        },
        cancelText: "cancelar",
        canCancel: false,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
