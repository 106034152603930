<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Contactos de proveedor</h1>

    <div class="control add-global-controls">
      <b-button type="is-primary" class="special-margin" @click="pushContact()"
        >Agregar contacto extra</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <div v-for="(item, index) in contacts" :key="index">
      <h3 class="form-division">Contacto {{ index + 1 }}</h3>

      <b-field grouped class="special-margin">
        <b-field label="Area (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Ventas"
            expanded
            icon="account-box-outline"
            v-model="item.AREA"
            required
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Nombre (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: Pedro Rodriguez"
            expanded
            icon="account-box-outline"
            v-model="item.NOMBRE"
            required
          ></b-input>
        </b-field>
        <b-field label="Email (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: compañia@mail.com"
            expanded
            icon="at"
            v-model="item.MAIL"
            required
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Teléfono (Requerido)" expanded label-position="inside">
          <b-input
            placeholder="Ejemplo: 3300000000"
            expanded
            icon="phone"
            v-model="item.TEL"
            required
          ></b-input>
        </b-field>
        <b-field
          label="Teléfono celular (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 3300000000"
            expanded
            icon="cellphone"
            v-model="item.CEL"
            required
          ></b-input>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls">
      <b-button type="is-success" @click="sendProvidersCosts()"
        >Guardar contacto de proveedor</b-button
      >
      <b-button type="is-danger" class="cancel-button"
        >Borrar información</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from "../../../event-bus";
export default {
  name: "AddProviderBankAccount",
  props: ["providersContacts", "providerId"],
  components: {},
  data() {
    return {
      currentContacts: 0,
      contacts: [],
    };
  },
  mounted() {
    if (this.providersContacts.length == 0) {
      let initialPhone = {
        AREA: "",
        NOMBRE: "",
        MAIL: "",
        TEL: "",
        CEL: "",
      };
      this.contacts.push(initialPhone);
      this.currentContacts++;
    } else {
      this.contacts = this.providersContacts;
      this.currentContacts = this.providersContacts.length;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    pushContact() {
      let tempPhone = {
        AREA: "",
        NOMBRE: "",
        MAIL: "",
        TEL: "",
        CEL: "",
      };
      this.contacts.push(tempPhone);
      this.currentContacts++;
    },
    sendProviderAccounts() {
      if (this.providerId) {
        EventBus.$emit("sendProvidersContacts", this.contacts, this.providerId);
      } else {
        EventBus.$emit("sendProvidersContacts", this.contacts);
      }

      this.$buefy.dialog.confirm({
        title: "Éxito",
        message: "Se guardó exitosamente los teléfonos de contacto",
        confirmText: "Entendido",
        type: "is-success",
        hasIcon: true,
        onConfirm: () => {
          this.closeModal();
        },
        cancelText: "cancelar",
        canCancel: false,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
