<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Proveedores </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Proveedores" icon="briefcase-account-outline">
        <ProvidersList />
      </b-tab-item>

      <b-tab-item label="Catálogo de giros" icon="briefcase-search-outline">
        <CommercialBusinessList :type="1" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ProvidersList from "../components/ProvidersList.vue";
import CommercialBusinessList from "../../Sales/components/CommercialBusinessList.vue";
export default {
  name: "AllProvidersList",
  components: {
    ProvidersList,
    CommercialBusinessList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "providers");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETBANKS"));
    if (this.$store.getters.COMMERCIALBUSINESS.length === 0) {
      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
    }
    if (this.$store.getters.SATCFDIUSES.length === 0) {
      allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
    }
    if (this.$store.getters.SATREGIMENES.length === 0) {
      allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
    }

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
